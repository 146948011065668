import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { Link, useParams } from "react-router-dom";
import Trianta from "../../constants/Api";
import { url } from "../../constants/Constants";
import Skeleton from "react-loading-skeleton";
import ProductItem from "../../Components/ProductItem";
import $ from "jquery";


const TrinataShopByCategoryPage = () => {

    const { id } = useParams();
    const [Category, setCategory] = useState({});
    const [Datas, setDatas] = useState([]);
    const [Tags, setTags] = useState([]);
    const [LoadTags, setLoadTags] = useState(true);
    const [Loading, setLoading] = useState(true);


    const Colors = ["text-primary", "text-secondary", "text-success", "text-danger", "text-warning ", "text-info"];
    const [KeyWord, setKeyWord] = useState("");

    function Search(e) {

        e.preventDefault();
        window.location.replace("/recherche/" + KeyWord);
    }

    useEffect(() => {

        $(document).ready(function () {
            $(window).scrollTop(0);
        });

        function getProductsBycategory() {

            Trianta.Category.detail(id).then(res => {
                if (res.data) {
                    setCategory(res.data);
                    if (res.data.tags.length !== 0) {
                        setTags(res.data.tags);
                        setLoadTags(false)
                    }
                    Trianta.Product.getbycat(id).then(res => {
                        if (res.data) {
                            setDatas(res.data);
                        }
                    }).catch();

                    setTimeout(() => {
                        setLoading(false)
                    }, 1000)
                }
            }).catch();
        }
        getProductsBycategory()

    }, [id]);



    return (
        <>
            <Navbar />

            {
                Category &&
                <section className="hero" id="categorie"

                    style={{ backgroundImage: Category.image ? `url(${url}/uploads/links/${Category.image})` : "" }}>

                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-7">
                                <h1 className="text-white">
                                    {
                                        Loading ? <Skeleton height={40} width={220} /> :
                                            Category.name
                                    }
                                </h1>
                                <h4 className="text-muted">
                                    {
                                        Loading ? <Skeleton height={30} width={320} /> :
                                            Category.description
                                    }

                                </h4>
                            </div>
                        </div>

                        <div className="row ">

                            <div className="col-md-7 col-12">
                                <form onSubmit={Search}>
                                    <div className="input-group-lg input-group mb-3 ">
                                        <input type="text" className="form-control input-search" name="keyword"
                                            onChange={(e) => setKeyWord(e.target.value)}
                                            placeholder="Trouver un produit" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                        <div className="input-group-append">
                                            <button className="btn btn-dark-search" type="submit" id="button-addon2">
                                                <i className="fas fa-search" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>

                </section>
            }


            <section className="py-5">
                <div className="container-fluid px-5">
                    <div className="row">
                        <div className="col-12 col-md-6 mb-4 mb-md-0 d-block d-md-none d-lg-none">
                            <nav className="navbar navbar-expand-lg navbar-light bg-transparent">
                                <span className="navbar-brand" >Tags</span>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav mr-auto">

                                        {
                                            Tags && Tags.length !== 0 &&
                                            Tags.map((el, i) => {
                                                let item = Colors[Math.floor(Math.random() * Colors.length)];

                                                return <li className="nav-item py-0" key={i}>
                                                    <a className="nav-link py-0" href={"/tag/" + el.id}>
                                                        <i className={`fas fa-tags ${item} mr-2`} />
                                                        {el.name}
                                                    </a>
                                                </li>
                                            })
                                        }

                                    </ul>

                                </div>
                            </nav>

                        </div>
                    </div>
                    <div className="row">

                        <div className="col-12 col-md-2 px-0 d-none d-md-block " >

                            {
                                Loading ?

                                    <div className="card border-0 bg-gray ">
                                        <div className="card-body  ">

                                            <Skeleton className="mb-2" height={40} />
                                            <Skeleton className="mb-2" height={40} />
                                            <Skeleton height={40} />

                                        </div>
                                    </div>
                                    :
                                    <div className="card border-0 bg-gray ">
                                        <div className="card-body  ">

                                            <ul className="list-group single">
                                                {
                                                    LoadTags ?
                                                        <>
                                                            <Skeleton className="mb-2" height={40} />
                                                            <Skeleton className="mb-2" height={40} />
                                                            <Skeleton height={40} />
                                                        </> :
                                                        Tags && Tags.length !== 0 &&
                                                        Tags.map((el, i) => {
                                                            let item = Colors[Math.floor(Math.random() * Colors.length)];

                                                            return <li className="cool-link " key={i}>
                                                                <Link to={"/tag/" + el.id} className="text-decoration-none text-dark">
                                                                    <i className={`fas fa-tags mr-2 ${item}`} />
                                                                    {el.name}
                                                                </Link>
                                                            </li>;

                                                        })
                                                }



                                            </ul>
                                        </div>
                                    </div>


                            }

                        </div>

                        <div className="col-12 col-md-10">


                            <div className="row ">

                                {
                                    Loading ?
                                        <>
                                            <div className="col-12 col-md-3">

                                                <Skeleton height={140} />
                                                <Skeleton height={40} />

                                            </div>
                                            <div className="col-12 col-md-3">

                                                <Skeleton height={140} />
                                                <Skeleton height={40} />

                                            </div>
                                            <div className="col-12 col-md-3">

                                                <Skeleton height={140} />
                                                <Skeleton height={40} />

                                            </div>
                                            <div className="col-12 col-md-3">

                                                <Skeleton height={140} />
                                                <Skeleton height={40} />

                                            </div>

                                        </>

                                        : <>
                                            {
                                                Datas.map((el, i) => {
                                                    return (
                                                        <ProductItem data={el} index={i} key={i} />
                                                    );
                                                })
                                            }

                                        </>
                                }



                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/*  Footer  */}
            <Footer />
        </>
    );
};

export default TrinataShopByCategoryPage;
