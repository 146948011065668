import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import logo from "../../Assets/trianta.png";
import { Link } from "react-router-dom";
import Trianta from "../../constants/Api";
import { rsa_key } from "../../constants/Constants";
import $ from "jquery";
import { useAuth } from "../../Context";
import { Jose } from "jose-jwe-jws";
import Input from "../../ui/Input";

const LoginPage = () => {

    const [view, setView] = useState("login");
    const [Error, setError] = useState(false);
    const [Error1, setError1] = useState(false);
    const [loading, setLoading] = useState(false);
    const [singup, setSingup] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [sendEmail, setsendEmail] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [errors, setErrors] = useState([]);
    const { setAuthTokens } = useAuth();
    let cryptographer = new Jose.WebCryptographer();

    let encrypter = new Jose.JoseJWE.Encrypter(cryptographer, rsa_key);

    if (redirect) {
        const redirectTo = sessionStorage.getItem('redirect');
        return <Redirect to={redirectTo ? redirectTo : '/'} />;
    }


    const userLogin = async (e) => {

        e.preventDefault();
        setLoading(true);
        let response = await Trianta.Users.login(new FormData(e.target));



        if (response.status === 200) {

            let user_data = {
                token: response.data.token,
                user: response.data.user.id,

            };

            if (response.data.user.billAddress) {
                user_data = { ...user_data, userId_Bil: response.data.user.billAddress.id }
            }

            let session = await encrypter.encrypt(JSON.stringify(user_data));

            sessionStorage.setItem('__trianta__token', session);

            setLoading(false);

            setAuthTokens(response.data.user.id);
            setRedirect(true);

        } else if (response.status === 400) {
            setLoading(false);
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 10000);
        }
    };

    const userSignUp = async (e) => {

        e.preventDefault();
        setSingup(true);
        let formData = new FormData(e.target);
        let response = await Trianta.Users.add(formData);

        setSingup(false);

        if (response.status === 200) {

            let user_data = {
                token: response.data.token,
                user: response.data.user.id,

            };

            if (response.data.user.billAddress) {
                user_data = { ...user_data, userId_Bil: response.data.user.billAddress.id }
            }

            let session = await encrypter.encrypt(JSON.stringify(user_data));

            sessionStorage.setItem('__trianta__token', session);

            setAuthTokens(response.data.user.id);

            setView("confirm")

        } else if (response.status === 400) {
            setErrors(response.data.errors);
            setError1(true);
            setTimeout(() => {
                setError1(false);
            }, 10000);

        }
    };

    function Confirm(e) {

        e.preventDefault();
        setConfirm(true);
        let formData = new FormData(e.target);
        Trianta.Users.verify(formData).then(res => {
            if (res.data.result) {

                setConfirm(false);
                $("#modalSuccess").modal("show");
                setTimeout(() => {
                    $("#modalSuccess").modal("hide");
                    window.location.replace("/")
                }, 6000);


            }
            else {
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 10000);

            }
        }).catch();


    }

    function email_send() {

        Trianta.Users.send_email().then(res => {
            if (res.data) {
                setsendEmail(true);
                setTimeout(() => {
                    setsendEmail(false);
                }, 6000);
            }
        }).catch();
    }

    const RenderView = (view) => {

        if (view === "login") {

            return <div className="login ">

                <div className="card">
                    <div className="card-body py-5">
                        <form className="login100-form validate-form text-center" onSubmit={userLogin}>

                            <Link to="/">
                                <img src={logo} className="img-fluid" width={110} alt="" />
                            </Link>

                            <span className="login100-form-title p-b-34 p-t-27">
                                Connexion
                            </span>
                            <div className="wrap-input100 mb-4 validate-input" data-validate="Enter username">
                                <input className="form-control" type="text" name="username"
                                    defaultValue = {sessionStorage.getItem("log") ?  sessionStorage.getItem("log") : ""}
                                    placeholder="Numéro de tél ou e-mail" />
                                <span className="focus-input100" ></span>
                            </div>
                            <div className="wrap-input100 mb-5 validate-input" data-validate="Enter password">
                                <input className="form-control" type="password" name="password" placeholder="Mot de passe" />
                                <span className="focus-input100" data-placeholder=""></span>
                            </div>

                            <div className="container-login100-form-btn mb-4">
                                <button className="btn btn-dark btn-block">
                                    {
                                        loading ?
                                            <span className="spinner-border spinner-border-sm" role="status"></span> :
                                            <> <span>Se connecter</span> </>
                                    }

                                </button>
                            </div>

                            {
                                Error ?
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="alert alert-danger" role="alert">
                                                Veuillez saisir correctement vos identifiants
                                            </div>
                                        </div>

                                    </div>
                                    : null
                            }

                            <p>
                                Vous n'avez pas de compte, <span onClick={() => setView("user")} className="text-decoration-none text-orange log">Inscrivez-vous</span>
                            </p>
                        </form>
                    </div>
                </div>

            </div>

        }
        else if (view === "user") {

            return <div className="signup  ">

                <div className="card">
                    <div className="card-body text-center py-5">
                        <Link to="/">
                            <img src={logo} className="img-fluid" width={110} alt="" />
                        </Link>

                        <span className="login100-form-title p-b-34 p-t-27">
                            Inscription
                        </span>


                        <form className="login100-form validate-form text-center" onSubmit={userSignUp}>

                            <div className="wrap-input100 mb-4 validate-input" >
                                <Input className="form-control" error={errors['firstname']} name="firstname" placeholder="Nom : " />
                                <span className="focus-input100" />
                            </div>
                            <div className="wrap-input100 mb-4 validate-input" >
                                <Input className="form-control" error={errors['lastname']} name="lastname" placeholder="Prénom : " />
                                <span className="focus-input100" />
                            </div>

                            <div className="wrap-input100 mb-4 validate-input" >
                                <Input className="form-control" error={errors['email']} type="email" name="email" placeholder="E-mail : " required />
                                <span className="focus-input100" />
                            </div>
                            <div className="wrap-input100 mb-4 validate-input" >
                                <Input className="form-control" error={errors['phone']} type="tel" name="phone" placeholder="Téléphone : " />
                                <span className="focus-input100" />
                            </div>
                            <div className="wrap-input100 mb-5 validate-input" >
                                <input className="form-control" type="password" name="password" placeholder="Mot de passe : " />
                                <span className="focus-input100" />
                            </div>

                            {
                                Error1 ?
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="alert alert-danger" role="alert">
                                                Veuillez saisir correctement les champs obligatoires
                                            </div>
                                        </div>

                                    </div>
                                    : null
                            }

                            <div className="container-login100-form-btn mb-4">
                                <button className="btn btn-dark btn-block ">
                                    {singup ?
                                        <span className="spinner-border spinner-border-sm" role="status"></span> :
                                        <> <span>S'inscrire</span> </>
                                    }
                                </button>
                            </div>

                            {/*<p >*/}
                            {/*    Ou s'inscire en tant que  <span onClick={()=>setView("seller")} className="text-decoration-none text-orange log">Vendeur</span>*/}
                            {/*</p>*/}

                            <p className="mt-4">
                                Vous avez déjà un compte ? <span onClick={() => setView("login")} className="text-decoration-none text-orange log">Connectez-vous</span>
                            </p>
                        </form>
                    </div>
                </div>

            </div>

        }
        else if (view === "confirm") {

            return <div className="signup  ">

                <div className="card">
                    <div className="card-body text-center py-5">
                        <Link to="/">
                            <img src={logo} className="img-fluid" width={110} alt="" />
                        </Link>

                        <span className="login100-form-title p-b-34 p-t-27">
                            Confirmation de Compte
                        </span>


                        <form className="login100-form validate-form text-center" onSubmit={Confirm}>

                            <p className="small">Veuillez consulter votre boite de réception correspondant à l'adresse e-mail que vous avez fournie et copier le code de confirmation pour valider votre compte.</p>

                            <div className="wrap-input100 mb-4 validate-input" data-validate="Enter password">
                                <input className="form-control" type="text" name="confirm_token" placeholder="Ex: 8908" />
                                <span className="focus-input100" data-placeholder=""></span>
                            </div>

                            {
                                Error ?
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="alert alert-danger" role="alert">
                                                Le code que vous avez saisi ne correspond pas.
                                            </div>
                                        </div>

                                    </div>
                                    : null
                            }

                            <div className="container-login100-form-btn mb-4 ">
                                <button className="btn btn-dark  w-100">
                                    {confirm ?
                                        <span className="spinner-border spinner-border-sm" role="status"></span> :
                                        <> <span>Confirmez le compte</span> </>
                                    }
                                </button>
                            </div>


                            <p className="text-muted">Vous n'avez pas reçu le code ? <span className="text-orange" onClick={() => email_send()} style={{ cursor: "pointer" }}>Cliquez ici pour renvoyer à nouveau</span>    </p>

                            {
                                sendEmail ?
                                    <div className="alert alert-success mt-4 " role="alert">
                                        Verifiez votre boite de reception

                                    </div>
                                    : null
                            }

                        </form>
                    </div>
                </div>

            </div>

        }

    };


    return (

        <>

            <div className="Login" >

                {RenderView(view)}



            </div>

            <div id="modalSuccess" className="modal fade" role="dialog">
                <div className="modal-dialog modal-dialog-centered">


                    <div className="modal-content">
                        <div className="page-body p-5">
                            <div className="head mb-4">
                                <h3 className="mt-2">Bienvenu(e) Chez Trianta Shop</h3>
                                <p>Votre compte a été validé avec succès. </p>
                            </div>

                            <h1 className="text-center">
                                <div className="checkmark-circle">
                                    <div className="background"></div>
                                    <div className="checkmark draw"></div>
                                </div>
                            </h1>

                        </div>
                    </div>
                </div>

            </div>
        </>



    );
};

export default LoginPage;
