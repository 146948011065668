import React, {useEffect} from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import $ from "jquery";

const Reglements = () => {

    useEffect(()=>{
        $(document).ready(function(){
            $(window).scrollTop(0);
        });
    },[]);

    return (
        <>
            <Navbar/>

            <section className=" d-flex justify-content-center align-items-center align-content-center">

                <div className="container bg-gray py-5  ">
                    <div className="row p-5">
                        <div className="col-12 col-md-10">
                            <h1 className="h2 text-orange">
                                Termes et Conditions
                            </h1>
                        </div>
                    </div>
                </div>

            </section>


            {/*    Service Section*/}
            <section className="pb-5">


                <div className="container pt-5">

                    <div className="row  ">

                        <div className="col-12 col-md-12 px-0 mb-5">

                            <div className="card w-100">
                                <div className="card-body p-5">
                                    <h4 className="font-weight-bold mb-4">Livraison & Commande</h4>

                                    <ul className="list-unstyled">
                                        <li>
                                            <span className="text-orange mr-2">-</span>
                                            Pour toute Livraison à domicile, payement en espèces à la livraison.
                                        </li>
                                        <li>
                                            <span className="text-orange mr-2">-</span>
                                            Le frais de livraison , soit<span className="text-orange">3$</span>,
                                            est payable pour moins de 20$ d’achat par facture et non par articles
                                        </li>

                                        <li> <span className="text-orange mr-2">-</span>Temps de livraison, <span className="text-orange">
                                            1 à 3 jours
                                        </span> (après confirmation du panier )

                                        </li>

                                        <li> <span className="text-orange mr-2">-</span>
                                            Commande 24H24 jours 7/7
                                            Livraison 7 jour sur 7 de 8h-19h à l’heure voulue par le client.
                                        </li>

                                        <li>
                                            <span className="text-orange mr-2">-</span>Si vous n’êtes pas satisfait de votre achat  , pour n’importe quelle raison , vous avez tout votre droit de nous retourner la marchandise en état livré mais à condition que çela soit le même jour de la réception du colis
                                        </li>
                                        <li>
                                            <span className="text-orange mr-2">-</span>
                                            En cas de refus d’article pour des raisons valables ( exemple articles qui ne pas en bon état) le frais de la livraison ne pas payant;
                                        </li>
                                        <li>
                                            <span className="text-orange mr-2">-</span>
                                            En cas de refus d’article pour des raisons personnelles , le frais de la livraison reste payant.
                                        </li>

                                    </ul>

                                </div>
                            </div>


                        </div>



                    </div>
                </div>

            </section>

            {/*    End Service Section*/}

            {/*    Footer*/}
            <Footer/>
        </>
    );

};

export default Reglements;
