 import axios from "axios";
import {url} from "./Constants";

import {Jose} from "jose-jwe-jws";
import {rsa_key_P} from "./Constants";

let cryptographer = new Jose.WebCryptographer();
let decrypter = new Jose.JoseJWE.Decrypter(cryptographer, rsa_key_P);


const getSession = async () => {
    try {
        if (sessionStorage.getItem('__trianta__token')){
            let session = JSON.parse(await decrypter.decrypt(sessionStorage.getItem('__trianta__token')));
            return session.token;
        }
    }catch (e) {

    }

    return null;
};

const Axios = axios.create({
    baseURL : url,
});

const Category = {

    get(){
        return Axios.get("/types");
    },

    detail(id) {
        return Axios.get("/types/" + id);
    },
    count() {
        return Axios.get("/types?count=1")
    }
};

const Tags = {

    get(){
        return Axios.get("/tags");
    },

    detail(id) {
        return Axios.get("/tags/" + id);
    },

};

const Product = {
    get(){
        return Axios.get("/products");
    },
    detail(id){
        return Axios.get("/products/" + id);
    },

    getbycat(id) {
        return Axios.get("/products?type=" + id);
    },
    getbyTag(id) {
        return Axios.get("/products?tags=" + id);
    },
    getbyKeyword(word) {
        return Axios.get("/products?keyword=" + word);
    },
    
};

const Commande = {

    async post(data){
        return await Axios.post("/commandes", data,{
            headers : {
                Authorization: await getSession()
            }
        });
    },
    async poste(data){
        return await Axios.post("/commande", data,{
            headers : {
                Authorization: await getSession()
            }
        });
    },
    detail(id){
        return Axios.get("/commandes/" + id);
    },

};

const  Users = {
    async login(data){
        return await Axios.post("/login", data).then(responses => {
                return responses;
            }
        ).catch(error => {
            return Object.assign({}, error).response;
        });
    },

    async send_email(){
        return await Axios.post("/email_send",[],{
            headers : {
                Authorization: await getSession()
            }
        });
    },
    add(data){
        return Axios.post("/users",data).then(responses => {
                return responses;
            }
        ).catch(error => {
            return Object.assign({}, error).response;
        });
    },
    async verify(data){
        return await Axios.post("/confirmation",data,{
            headers : {
                Authorization: await getSession()
            }
        }).then(responses => {
                return responses;
            }
        ).catch(error => {
            return Object.assign({}, error).response;
        });
    },

    async update(id,data){
        return await Axios.post("/users/"+id+"?_method=PUT" ,data,{
            headers : {
                Authorization: await getSession()
            }
        })
    },

    detail(id) {
        return Axios.get("/users/" + id);
    },

    async send_bil_adresse(data) {
        return await Axios.post("/bill_address",data,{
            headers : {
                Authorization: await getSession()
            }
        }).then(responses => {
                return responses;
            }
        ).catch(error => {
            return Object.assign({}, error).response;
        });
    },

    async updateBil_ad(id,data){
        return await Axios.post("/bill_address/"+id+"?_method=PUT" ,data,{
            headers : {
                Authorization: await getSession()
            }
        })
    },

};


const Trianta = {Category, Commande, Product, Users, Tags};

export default Trianta;
