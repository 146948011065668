import React from 'react';
import logo from "../../Assets/trianta.svg"
import {Link} from "react-router-dom";
import {useAuth} from "../../Context";

const Navbar = () => {

    const {cart} = useAuth();


    // const [cart, setCart] = useState([]);
    sessionStorage.removeItem('redirect');


    return (

        <nav className="navbar navbar-expand-lg navbar-light bg-white py-0" tabIndex="0">
            <div className="container">

                <a className="navbar-brand" href="/">
                    <img src={logo} className="img-fluid" width={110} alt=""/>
                </a>

                <div className={`nav-item float-right`}>

                    <Link to="/checkout" className=" pb-0">
                        <i className="fas fa-shopping-cart text-orange mr-2 pt-3" style={{fontSize: "20px"}}/>

                        <span className="badge trianta-badge">
                                    {
                                        cart ? cart.length : ""
                                    }
                               </span>

                    </Link>
                </div>


            </div>
        </nav>
    );

};

export default Navbar;
