import React, {useEffect, useState} from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import {useParams} from "react-router-dom";
import Trianta from "../../constants/Api";
import {url} from "../../constants/Constants";
import Skeleton from "react-loading-skeleton";
import ProductItem from "../../Components/ProductItem";
import {useAuth} from "../../Context";
import $ from "jquery";

const ProductPage = () => {

    window.scrollTo(0,0);
    const {updateCart} = useAuth();

    const {id} = useParams();
    const [details, setDetails] = useState({});
    const [similar, setSimilar] = useState([]);
    const [quantite, setQuantite] = useState("1");
    const [Loading, setLoading] = useState(false);
    

    let Cart = {

        id_produit: details.id,
        image: details.cover,
        prix: details.prix,
        name: details.name,
        quantity: quantite

    };


    function AddToCart() {

        let cart = [Cart];

        if (sessionStorage.getItem("cart")) {

            let cartToUpdate = JSON.parse(sessionStorage.getItem("cart"));
            let cart_item = null;

            for (let key in cartToUpdate) {

                if (cartToUpdate.hasOwnProperty(key)) {

                    if (cartToUpdate[key].id_produit === Cart.id_produit) {

                        cart_item = cartToUpdate[key];
                    }

                }
            }
            if (cart_item === null) {
                cartToUpdate.push(Cart);
            } else {
                let index = cartToUpdate.indexOf(cart_item);
                cartToUpdate[index].quantity = Number(cartToUpdate[index].quantity) + Number(Cart.quantity);
            }

            $("#modalSuccess").modal("show");
            setTimeout(() => {
                $("#modalSuccess").modal("hide");
            }, 3000);

            sessionStorage.removeItem("cart");
            sessionStorage.setItem("cart", JSON.stringify(cartToUpdate));
            updateCart(cartToUpdate);

        }
        else {

            sessionStorage.setItem("cart", JSON.stringify(cart));
            $("#modalSuccess").modal("show");
            setTimeout(() => {
                $("#modalSuccess").modal("hide");
            }, 3000);
            updateCart(JSON.parse(sessionStorage.getItem("cart")));

        }

    }

    useEffect(() => {

        $(document).ready(function(){
            $(window).scrollTop(0);
        });

        function getDetail() {

            setLoading(true);
            Trianta.Product.detail(id).then(res => {
                if (res.data) {

                    setDetails(res.data);

                    Trianta.Product.getbyTag(res.data.tags[0].id).then(det => {
                        if (det.data) {
                            setSimilar(det.data);
                        }
                    }).catch();

                    setTimeout(() => {
                        setLoading(false);

                    }, 1000)
                }
            }).catch();


        }
        getDetail();


    }, [id]);


    return (
        <>
            <Navbar/>

            <section className="py-0">

                <div className="container">

                    <div className="row">

                        <div className="col-md-12 ">

                            {
                                Loading ?
                                    <div className="card product-details pt-5">
                                        <div className="card-body overflow-hidden">

                                            <div className="row w-100 overflow-hidden">


                                                <div className="col-12 col-sm-12 col-md-6 d-flex overflow-hidden justify-content-center align-content-center align-items-center">

                                                    <div className="box-img-detail">
                                                        <Skeleton className="d-none d-md-block" width={450} height={450}/>
                                                        <Skeleton className="d-block d-md-none" width={350} height={350}/>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-12 col-md-6 pt-5 pt-md-0 pl-0 pl-md-4 overflow-hidden ">

                                                    <Skeleton width={200} height={40} className="mb-4"/>

                                                    <Skeleton width={420} height={40} count={4} className="mb-4"/>

                                                    <div className="d-inline-flex w-100">
                                                        <Skeleton width={200} height={40}/>
                                                        <Skeleton width={200} height={40} className="ml-3"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :

                                    <div className="card product-details pt-5 ">
                                        <div className="card-body p-0">

                                            <div className="row">

                                                {
                                                    "name" in details && details ?
                                                        <>
                                                            <div
                                                                className="col-12 col-sm-12 col-md-6 d-flex justify-content-center align-content-center align-items-center">
                                                                <div className="box-img-detail" >
                                                                    <img
                                                                        src={url + "/uploads/products/" + details.cover}
                                                                        alt=""/>
                                                                    {/*<img src={url + "/uploads/products/" + details.cover} alt=""/>*/}
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-12 col-md-6 pt-5 pt-md-0">

                                                                {/*<span*/}
                                                                {/*    className="badge badge-pill badge-trianka"> New</span>*/}

                                                                <h1 className="mt-4 text-uppercase text-dark font-weight-bold">
                                                                    {details.name}
                                                                </h1>


                                                                <p className="font-weight-bold text-lowercase des small text-muted mb-0"
                                                                      >
                                                                    {details.product_reference}
                                                                    </p>



                                                                <div className="d-inline-flex justify-content-between mt-4 w-100">

                                                                    <span className="mr-5">
                                                                        <p className="mb-1">Prix : </p>

                                                                        <h3 className="font-weight-bold text-orange">
                                                                            {Number(details.prix)}
                                                                        </h3>
                                                                    </span>
                                                                    <span>
                                                                        <p className="mb-1">Quantite : </p>

                                                                        <div className="form-group">
                                                                            <input type="number" name="quantity"
                                                                                   defaultValue="1"
                                                                                   onChange={(e) => setQuantite(e.target.value)}
                                                                                   className="form-control custom-control"/>

                                                                        </div>
                                                                    </span>

                                                                </div>

                                                                <nav >
                                                                    <div className="nav nav-tabs" id="nav-tab"
                                                                         role="tablist">
                                                                        <a className="nav-link pl-1 active"
                                                                           id="nav-home-tab" data-toggle="tab"
                                                                           href="#nav-home" role="tab"
                                                                           aria-controls="nav-home"
                                                                           aria-selected="true">Description</a>

                                                                    </div>
                                                                </nav>

                                                                <div className="tab-content mt-3" id="nav-tabContent">
                                                                    <div className="tab-pane fade show active"
                                                                         id="nav-home" role="tabpanel"
                                                                         aria-labelledby="nav-home-tab">
                                                                        <p className="text-lowercase des">
                                                                            {details.description}
                                                                        </p>
                                                                    </div>


                                                                </div>

                                                                <div className="d-block flex-column flex-md-row d-md-flex justify-content-md-between mt-4 w-100"
                                                                     >

                                                                    <p className="mr-5 mb-0 text-left">

                                                                        <span>Total : </span><br/>
                                                                        <span className="text-orange font-weight-bold">
                                                                            {
                                                                                quantite ?
                                                                                ("$ " + parseFloat(details.prix) * parseFloat(quantite)) : details.prix

                                                                            }
                                                                        </span>

                                                                    </p>
                                                                    <button className="btn btn-dark float-md-right float-left mt-4 mt-md-0"
                                                                            onClick={() => AddToCart()}>
                                                                        <span>
                                                                            <i className="fas fa-cart-plus mr-2"/>
                                                                        </span>
                                                                        <span>Ajouter au panier</span>
                                                                    </button>

                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    </div>

                            }


                        </div>

                    </div>

                </div>

            </section>

            <section className="py-5">

                <div className="container">
                    <header className="mb-5">

                        <h2 className="h5 text-uppercase mb-4">Articles similaires</h2>
                    </header>

                    <div className="row ">

                        {
                            Loading ?
                                <>
                                    <div className="col-12 col-md-3">

                                        <Skeleton height={140}/>
                                        <Skeleton height={40}/>

                                    </div>
                                    <div className="col-12 col-md-3">

                                        <Skeleton height={140}/>
                                        <Skeleton height={40}/>

                                    </div>
                                    <div className="col-12 col-md-3">

                                        <Skeleton height={140}/>
                                        <Skeleton height={40}/>

                                    </div>
                                    <div className="col-12 col-md-3">

                                        <Skeleton height={140}/>
                                        <Skeleton height={40}/>

                                    </div>

                                </>

                                : <>
                                    {
                                        similar.map((el, i) => {
                                            return (
                                                <ProductItem index={i} data={el} />
                                            );
                                        })
                                    }
                                </>
                        }


                    </div>
                </div>
            </section>

            <div id="modalSuccess" className="modal fade" role="dialog">
                <div className="modal-dialog modal-dialog-centered">


                    <div className="modal-content">
                        <div className="page-body p-5">
                            <div className="head mb-4">
                                <h3 className="mt-2">Le produit a été ajouté au panier avec succés</h3>

                            </div>

                            <h1 className="text-center">
                                <div className="checkmark-circle">
                                    <div className="background"></div>
                                    <div className="checkmark draw"></div>
                                </div>
                            </h1>

                        </div>
                    </div>
                </div>

            </div>

            <Footer/>

        </>
    );
};

export default ProductPage;
