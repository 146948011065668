import React,{useState,useEffect} from "react";
import { AuthContext } from "./Context/index";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import AOS from 'aos';
import './App.css';
import Homepage from "./Pages/Homepage";
import CheckoutPage from "./Pages/CheckoutPage";
import LoginPage from "./Pages/LoginPage";
import ProductPage from "./Pages/ProductPage";
import TrinataShopByCategoryPage from "./Pages/TrinataShopByCategoryPage";
import ProfilePage from "./Pages/ProfilePage";
import {Jose} from "jose-jwe-jws";
import {rsa_key_P} from "./constants/Constants";
import PrivateRoute from "./Components/PrivateRoute";
import TrinataShopByTagPage from "./Pages/TrinataShopByTagPage";
import Reglements from "./Pages/Reglements";
import TrinataShopPage from "./Pages/TrinataShopPage";

function App() {

    let cryptographer = new Jose.WebCryptographer();
    let decrypter = new Jose.JoseJWE.Decrypter(cryptographer, rsa_key_P);

    const [authTokens, setAuthTokens] = useState();
    const [AuthToken, setAuthToken] = useState();
    const [userId_Bil, setUserId_Bil] = useState();


    const [cart, setCat] = useState(
        JSON.parse(sessionStorage.getItem("cart"))
    );

    const setTokens = (data) => {
        setAuthTokens(data);
    };

    // const updateuserId_Bil = (data) => {
    //     setAuthTokens(data);
    // };

    const updateCart = (data) => {
        setCat(data)
    };

    const getSession = async () => {
        try {
            if (sessionStorage.getItem('__trianta__token')){
                let session = JSON.parse(await decrypter.decrypt(sessionStorage.getItem('__trianta__token')));
                setAuthTokens(session.user);
                setAuthToken(session.token);
                setUserId_Bil(session.userId_Bil);
            }
        }catch (e) {

        }
    };

    useEffect( () => {

        getSession();

        AOS.init({
            duration : 3000
        });


    });

    return (
        <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens ,updateuserId_Bil: setUserId_Bil , cart, updateCart : updateCart,AuthToken: AuthToken,userId_Bil: userId_Bil}}>
            <Router>
                <Switch>

                    <Route exact path="/" component={Homepage} />
                    {/*<Route path="/about" component={AboutPage} />*/}
                    <Route path="/login" component={LoginPage} />
                    <Route path="/checkout" component={CheckoutPage} />
                    <Route path="/article/:id" component={ProductPage} />
                    <Route path="/recherche/:keyword" component={TrinataShopPage} />
                    <Route path="/category/:id" component={TrinataShopByCategoryPage} />
                    <Route path="/tag/:id" component={TrinataShopByTagPage} />
                    {/*<Route path="/contacts" component={ContactPage} />*/}
                    <PrivateRoute path="/profile" component={ProfilePage} />
                    <Route path="/reglements" component={Reglements} />

                </Switch>
            </Router>
        </AuthContext.Provider>
    );
}

export default App;
