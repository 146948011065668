import React, {useEffect, useState} from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import Trianta from "../../constants/Api";
import $ from "jquery";
import {useAuth} from "../../Context";
import {Jose} from "jose-jwe-jws";
import {rsa_key, rsa_key_P} from "../../constants/Constants";

let cryptographer = new Jose.WebCryptographer();
let decrypter = new Jose.JoseJWE.Decrypter(cryptographer, rsa_key_P);
let encrypter = new Jose.JoseJWE.Encrypter(cryptographer, rsa_key);

const ProfilePage = () => {

    const [User, setUser] = useState({});
    const [Load, setLoad] = useState(true);
    const [Load1, setLoad1] = useState(false);
    const [Load2, setLoad2] = useState(false);
    const [bill, setBill] = useState(null);
    const {authTokens, userId_Bil,updateuserId_Bil} = useAuth();

    console.log(userId_Bil);

    function UpdateUser(e) {

        e.preventDefault();
        setLoad1(true);
        let formData = new FormData(e.target);
        Trianta.Users.update(authTokens, formData).then(res => {
            if (res.data) {

                $("#modalSuccess").modal("show");
                setTimeout(() => {
                    $("#modalSuccess").modal("hide");
                }, 3000)
            }
        }).catch();
    }

    async function UpdateUserBil_ad(e) {

        e.preventDefault();
        setLoad2(true);
        let formData = new FormData(e.target);
        formData.append("zip","7110501");
        formData.append("country","RD-Congo");
        if (userId_Bil) {
            Trianta.Users.updateBil_ad(userId_Bil, formData).then(res => {
                if (res.data) {

                    setLoad2(false);
                    $("#modalSuccess").modal("show");
                    setTimeout(() => {
                        $("#modalSuccess").modal("hide");
                    }, 3000)
                }
            }).catch(e => setLoad2(false));
        }
        else {
            formData.append("user", authTokens);
            let response = await Trianta.Users.send_bil_adresse(formData);
            setLoad2(false);


            if (response.status === 200){

                try {
                    if (sessionStorage.getItem('__trianta__token')){
                        let session = JSON.parse(await decrypter.decrypt(sessionStorage.getItem('__trianta__token')));
                        let user_data = {...session,userId_Bil: response.data.values};
                        let sessionSave = await encrypter.encrypt(JSON.stringify(user_data));
                        sessionStorage.setItem('__trianta__token', sessionSave);
                        updateuserId_Bil(response.data.values)
                    }
                }catch (e) {

                }

                $("#modalSuccess").modal("show");
                setTimeout(() => {
                    $("#modalSuccess").modal("hide");
                }, 3000)
            }
        }

    }

    useEffect(() => {
        $(document).ready(function(){
            $(window).scrollTop(0);
        });
        setLoad(true);
        Trianta.Users.detail(authTokens).then(res => {
            if (res.data) {
                setUser(res.data);
                setLoad(false);
                setBill(res.data.billAddress)
            }
        }).catch(e => setLoad(false));
    }, [authTokens]);

    return (
        <div>
            <Navbar/>

            <section className=" d-flex justify-content-center align-items-center align-content-center">

                <div className="container bg-gray py-5  ">
                    <div className="row p-5">
                        <div className="col-12 col-md-10">
                            <h1 className="text-orange">
                                Mon profile Trianta Shop
                            </h1>
                            <h4 className="text-dark">Username</h4>
                        </div>
                    </div>
                </div>

            </section>


            {/*    Service Section*/}
            {!Load && <section className="pb-5">


                <div className="container pt-5">

                    <div className="row  ">

                        <div className="col-12 col-md-12 px-0 mb-5">

                            <div className="card w-100">
                                <div className="card-body p-5">

                                    <h4 className="font-weight-bold mb-5">Informations Personnelles </h4>

                                    <form onSubmit={UpdateUser}>
                                        <div className="form-row">

                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="username">Nom</label>
                                                    <input className="form-control" type="text"
                                                           name="firstname" placeholder="Username"
                                                           defaultValue={User.firstname}/>

                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="nickname">Prénom</label>
                                                    <input className="form-control" type="text"
                                                           name="lastname" placeholder="Nom"
                                                           defaultValue={User.lastname}/>

                                                </div>
                                            </div>

                                        </div>
                                        <div className="form-row mt-3">

                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="password">Nouveaun mot de passe </label>
                                                    <input className="form-control" type="password"
                                                           name="password" placeholder=""/>

                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="tel">Téléphone : </label>
                                                    <input type="tel" className="form-control" name="phone"
                                                           defaultValue={User.phone}
                                                           aria-describedby="emailHelp"/>

                                                </div>
                                            </div>

                                        </div>

                                        <div className="form-row mt-4">

                                            <div className="col-12 col-md-12 text-right">
                                                <button className="btn btn-dark btn-lg " type="submit">
                                                    {
                                                        Load1 ?
                                                            <span
                                                                className="spinner-border spinner-border-sm"
                                                                role="status"/> :
                                                            <span>Mettre à jour </span>
                                                    }

                                                </button>
                                            </div>


                                        </div>
                                    </form>
                                </div>
                            </div>


                        </div>
                        <div className="col-12 col-md-12 px-0 ">

                            <div className="card w-100">
                                <div className="card-body p-5">
                                    <form onSubmit={UpdateUserBil_ad}>
                                        <div className="form-row ">

                                            <div className="col-12 col-md-12">
                                                <h4 className="font-weight-bold mb-5">Adresse de
                                                    Facturation </h4>
                                            </div>


                                        </div>
                                        <div className="form-row">
                                            <div className="col-lg-6 form-group mt-3">
                                                <label className="text-small"
                                                       htmlFor="firstName">Nom</label>
                                                <input className="form-control form-control"
                                                       required
                                                       name="firstname" type="text"
                                                       placeholder="Votre nom"
                                                       defaultValue={bill ? bill.firstname : User.firstname}/>
                                            </div>
                                            <div className="col-lg-6 form-group mt-3">
                                                <label className="text-small"
                                                       htmlFor="lastName">Prénom</label>
                                                <input className="form-control form-control" name="lastname"
                                                       type="text"
                                                       defaultValue={bill ? bill.lastname : User.lastname}
                                                       placeholder="Votre prénom"/>
                                            </div>

                                            <div className="col-lg-6 form-group mt-3">
                                                <label className="text-small" htmlFor="phone">Numéro de
                                                    téléphone <sup>*</sup></label>
                                                <input className="form-control form-control" name="phone"
                                                       type="tel"
                                                       required
                                                       defaultValue={bill ? bill.phone : User.phone}
                                                       placeholder="Ex +243 999 999 999 999"/>
                                            </div>

                                            <div className="col-lg-6 form-group mt-3">
                                                <label className="text-small" htmlFor="city">Ville</label>
                                                <input className="form-control form-control" name="city"
                                                       type="text"
                                                       defaultValue={bill ? bill.city : null}
                                                       placeholder="Ex. Lubumbashi"/>
                                            </div>



                                        </div>
                                        <div className="form-row mt-3">

                                            <div className="col-12 col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="message">Adresse de livraision <sup>*</sup></label>
                                                    <p className="small">
                                                        Ex : Numéro : 01 / Avenue : Kava-vubu / Quartier : Golf-Malela / Commune : Lubumbashi / Reference : Terminus Malela
                                                    </p>
                                                    <textarea className="form-control" name="address"
                                                              required
                                                              defaultValue={bill ? bill.address : null}
                                                              placeholder="Adresse" rows="2"/>
                                                </div>
                                            </div>


                                        </div>

                                        <div className="form-row mt-4">

                                            <div className="col-12 col-md-12 text-right">
                                                <button className="btn btn-dark btn-lg " type="submit">
                                                    {
                                                        Load2 ?
                                                            <span
                                                                className="spinner-border spinner-border-sm"
                                                                role="status"></span> :
                                                            <span>Mettre à jour </span>
                                                    }

                                                </button>
                                            </div>


                                        </div>

                                    </form>
                                </div>
                            </div>


                        </div>


                    </div>
                </div>

            </section>
            }
            {/*    End Service Section*/}

            <div id="modalSuccess" className="modal fade" role="dialog">
                <div className="modal-dialog modal-dialog-centered">


                    <div className="modal-content">
                        <div className="page-body">
                            <div className="head mb-4">
                                <h3 className="mt-2">Mise à jour éffectuéé avec succés</h3>

                            </div>

                            <h1 className="text-center">
                                <div className="checkmark-circle">
                                    <div className="background"></div>
                                    <div className="checkmark draw"></div>
                                </div>
                            </h1>

                        </div>
                    </div>
                </div>

            </div>

            {/*    Footer*/}
            <Footer/>
        </div>
    );

};

export default ProfilePage;
