import React, { useState } from 'react';
import { Link } from "react-router-dom";
// import Trianta from '../../constants/Api';
import { useAuth } from "../../Context";

const Footer = () => {

    const [email, setemail] = useState("")
    let path = window.location.pathname;
    const { authTokens } = useAuth();

    function Logout() {
        sessionStorage.removeItem('__trianta__token');
        if (window.location.pathname === '/profile') {
            window.location.replace("/");
        } else {
            window.location.reload();
        }

    }

    function Log(e) {

        e.preventDefault();
        sessionStorage.setItem('log', email);
        window.location.replace("/login")
    }


    const onLogin = (e) => {
        e.preventDefault();
        sessionStorage.setItem('log', email);
        sessionStorage.setItem('redirect', path);
        window.location.replace('/login');
    };

    return (

        <footer className="bg-gray" >
            <section className="footer py-5">
                <div className="container">
                    <div className="row justify-content-between">

                        <div className="col-12 col-md-4 mb-4 mb-sm-0">
                            <h2>Trianta</h2>
                            <p className="my-4 text-justify">
                                Trianta est une boutique en ligne spécialisée dans la vente de plusieurs articles venant d'Europe, avec livraison à domicile dans la ville de Lubumbashi.
                            </p>

                            <div className="d-inline-flex justify-content-between">
                                <a href="https://web.facebook.com/Triantashop-104534031645988" rel="noreferrer" target="_blank" className="mr-5 text-orange text-decoration-none">
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                                <a href="https://www.instagram.com/trianta.shop/" target="_blank" rel="noreferrer" className="mr-5 text-orange text-decoration-none">

                                    <i className="fab fa-instagram"></i>

                                </a>

                            </div>
                        </div>


                        <div className="col-12 col-md-4 mb-4 mb-sm-0">
                            <h4>Navigation</h4>

                            <ul className="list-unstyled mt-4">
                                <li className="mb-2">
                                    <Link to="/" className="text-orange ">Accueil</Link>
                                </li>

                                <li className="mb-2">
                                    <Link to="/reglements" className="text-orange ">Termes & Conditions</Link>
                                </li>



                                <li className="mb-2">
                                    {
                                        authTokens
                                            ?
                                            <span className="nav-link dropdown">
                                                <span className=""
                                                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"


                                                    aria-expanded="false">
                                                    <i className="fas fa-user-circle mr-2 text-orange"
                                                        style={{ fontSize: "1.6em" }} />
                                                    {authTokens.username}
                                                </span>
                                                <div className="dropdown-menu dropdown-menu-right py-0"
                                                    aria-labelledby="dropdownMenuButton">
                                                    <Link to="/profile" className="dropdown-item py-0">Profile</Link>
                                                    <span className="dropdown-item py-0"
                                                        onClick={() => Logout()}>Déconnexion</span>

                                                </div>
                                            </span>

                                            : <>
                                                <span className="text-orange " >Connexion : </span>
                                                <div className="row text-center justify-content-center mt-3">
                                                    <form onSubmit={Log}>
                                                        <div className="col-auto">
                                                            <div className="input-group-lg input-group mb-3 ">
                                                                <input type="email"
                                                                    onChange={(e) => setemail(e.target.value)} className="form-control input-letter" placeholder="E-mail " aria-label="Recipient's username" aria-describedby="button-addon2" />
                                                                <div className="input-group-append">
                                                                    <button className="btn btn-dark" type="submit" onClick={onLogin} id="button-addon2">
                                                                        <i className="fas fa-sign-in-alt" aria-hidden="true"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </>
                                    }
                                </li>
                            </ul>
                        </div>


                    </div>

                </div>
            </section>

            <section className="bg-white pt-4 pb-4 pb-sm-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <p><i className="fa fa-copyright"></i> 2021 Trianta</p>
                        </div>
                        <div className="col-12 col-md-5">
                            <div className="text-right mr-4 d-inline-flex ">
                                <div className="mr-3">Terms of Use</div>
                                <div>Privacy Policy</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    );

};

export default Footer;
