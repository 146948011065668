import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Jose} from "jose-jwe-jws";

toast.configure();


export function notify(bool, success, error) {
    bool === true ? toast.success(success, {autoClose: 1500}) : toast.error(error, {autoClose: 1500});
};

// export const url = "http://server.triantashop.com";
export const url = "https://servertest.triantashop.com";


export const rsa_key = Jose.Utils.importRsaPublicKey(
    {
        "alg": "RSA-OAEP",
        "e": "AQAB",
        "ext": true,
        "key_ops": ["encrypt"],
        "kty": "RSA",
        "n": "ybNZj7YQqm8ovzpn7tdcFrmFkYNtzck__rOylFdAj44JSVs11pk9HLWOoNf5UHHGNfubWFsjTKv6TM1bt0uZRiX3d15JNQSsbq_Au1Wc3FGLBFZT07mnOszfLVogYLzO3r1gMpKmJ9SkmRlWJDCoDttziFI5C7K_4LGMDm6M-QSzrz-vIl9bvYalWEbYYFrqAAQkG60q3a4BKr8O6tEIZbd9GjdLX4cOSYCJyAg1yUnJcrMI1qYM8Py39_zh8FW1EfqdePB7sQrVMT1v0GVNd0UAPDTVgRaQaFWlhsUdPHZi5iWuDwaIH5RAwYDXl7jFHkYlGKTrwJQG7o6JI2W6Kw"
    },
    "RSA-OAEP"
);


export const rsa_key_P = Jose.Utils.importRsaPrivateKey(
    {
        "alg": "RSA-OAEP",
        "d": "FaeHqgIl7-ZXPZCLr_y3CkmIqYKuuzUsBbBNL9feOotJO_Lq6Msy4G4tjd8iCRzt5sXIe8ftFkIwcSZq_lbDceCJnOsmeOazjH6Z9oLOgGjQv_p0rXp1Fb8BBuFyMHCU1R0HwuYzqHsP4M_zi5cds9qjoLMTJMpgB0OIgdQklmdV2wKHgofBHTCDnBW-uF8ca2VGnbQFlybJRvbZoojh-KpR1NrMjWhwHUSdFIHM9Uw5IUrwEnuKD7L2KdAf7EzeNqiUajYsrdmjJN8OsKgu45ra4Njxz2BMznbH5TGIE4ms_P4ZtNBvvbkonrt0WNorAEWIziFCi-bYI6cELqjAIQ",
        "dp": "ILWTJKfBsV3xh0YHD-JnEBFl5AqsrMjUEDT-xZptXkgsvemo85gqFj8YD9Ix48My4ND7SFTPWQbC9oqtwiWdXtWXCh2ktqF12Dn-59SSCDLNHSE6QcMj3FXZT97HKYOTJ4rU3qff38PSkgsQi0n4L9rY2Z5kuMen8bzevldSEEM",
        "dq": "d-FcylbOXb1tjpjqajHchmM8d9PDyY4WFb2dIIdA7AW-lrlAmvxLXqEaLWZPWzkofpkfd0oYGMCbFZxmlRqYiIy8NxUA81Ix8fmMXK283g9HShw0E22bcuOM7vgeFvksXbl24vgdhwW3E8Q_BUtXXLTCZOMBoPp8ZXR0c1Ay4S0",
        "e": "AQAB",
        "ext": true,
        "key_ops": ["decrypt"],
        "kty": "RSA",
        "n": "ybNZj7YQqm8ovzpn7tdcFrmFkYNtzck__rOylFdAj44JSVs11pk9HLWOoNf5UHHGNfubWFsjTKv6TM1bt0uZRiX3d15JNQSsbq_Au1Wc3FGLBFZT07mnOszfLVogYLzO3r1gMpKmJ9SkmRlWJDCoDttziFI5C7K_4LGMDm6M-QSzrz-vIl9bvYalWEbYYFrqAAQkG60q3a4BKr8O6tEIZbd9GjdLX4cOSYCJyAg1yUnJcrMI1qYM8Py39_zh8FW1EfqdePB7sQrVMT1v0GVNd0UAPDTVgRaQaFWlhsUdPHZi5iWuDwaIH5RAwYDXl7jFHkYlGKTrwJQG7o6JI2W6Kw",
        "p": "93NzhYIfiNb67HQlLCmx4ZN81hV5pTRrPdKFO8IH4arOJm2Zu0wktuVxWuB16DbOcYfZRJcsWeWzB1j8oEQSN3dLI7AthD7mA_SCMke-5HQdbkPFk9Nd7VCxeYu3yTv6iVo7NHmWnFDNYA804IscRmvDfk7aHuvlroXDCuf8rY8",
        "q": "0KtD2Rr2xu6i-qb05AXWs-vP2su-rnER2NIJMZAGjLIF6yb9Rm0lA8pYctRmu-wW-t0q7TzerrSn7ie5BpG_QFmsmUOldn-9cyD_zerIe4DV1V1xok28RNRFxx_SMZ2-7F6KnP0glUevgaok2Vk3ydnudJcR8XbCSE82CEkz06U",
        "qi": "lxsu8HEWjsq_joKMvHVplD-LAhIZBGl65cotZqc_VAIX9HHidk5s1EUJN7xdSSIaeGfcCbHN0NLRri3QnT_Zht0nSGIAgSgBWhAvmkzh92L_AHtiFPm5s7oouIyPpZbebjgF91FqynkJ_Wv4xocOzmqktPlB_pZPdlLgE0D6JE4"
    },
    "RSA-OAEP"
);
