
import React, { useEffect, useState } from 'react';
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import banner from "../../Assets/images/banniere-trianta-cosmetic-6051b8c78abe3319557840.jpg";
import defaultImage from "../../Assets/images/banniere-trianta-cosmetic-6051b8c78abe3319557840.jpg"
import { Link } from "react-router-dom";
import Trianta from "../../constants/Api";
import { url } from "../../constants/Constants";
import PubSpace from "../../Components/PubSpace";
import Skeleton from "react-loading-skeleton";
import ProductItem from "../../Components/ProductItem";
import $ from "jquery";
const Homepage = () => {



    const [Datas, setDatas] = useState([]);
    const [Categorys, setCategorys] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [KeyWord, setKeyWord] = useState("");




    useEffect(() => {

        $(document).ready(function () {
            $(window).scrollTop(0);
        });
        function getProducts() {

            Trianta.Category.get().then(res => {
                if (res.data) {
                    setCategorys(res.data);

                    Trianta.Product.get().then(res => {
                        if (res.data) {
                            setDatas(res.data);
                        }
                    }).catch();

                    setTimeout(() => {
                        setLoading(false)
                    }, 1000)
                }
            }).catch();

        }
        getProducts();
    }, []);


    function Search(e) {

        e.preventDefault();
        window.location.replace("/recherche/" + KeyWord);
    }

    return (
        <>
            <Navbar />

            <section className="hero pb-3 bg-cover bg-center d-flex align-items-center"
                style={{ backgroundImage: `url(${banner})` }}>

                <div className="container py-5">
                    <div className="row px-4 px-lg-5">
                        <div className="col-md-7 col-12" data-aos='fade-up'>

                            <h1 className="h2 text-uppercase mb-3 text-white">{"Nouvelle Collection "+ new Date().getFullYear()}</h1>

                        </div>
                    </div>
                    <div className="row px-4 px-lg-5">

                        <div className="col-md-7 col-12">
                            <form onSubmit={Search}>
                                <div className="input-group-lg input-group mb-3 ">
                                    <input type="text" className="form-control input-search" name="keyword"
                                        onChange={(e) => setKeyWord(e.target.value)}
                                        placeholder="Trouver un produit" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                    <div className="input-group-append">
                                        <button className="btn btn-dark-search" type="submit" id="button-addon2">
                                            <i className="fas fa-search" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </section>

            {/*CATEGORIES SECTION*/}

            <section className="pt-5">
                <header className="text-center pb-5">
                    {/*<p className="small text-muted small text-uppercase mb-1">Des articles soigneusement créés</p>*/}
                    <h2 className="h5 text-uppercase ">Découvrez nos categories</h2>
                </header>
                <div className="container-fluid px-5">

                    <div className="row ">

                        {
                            Loading ?
                                <>
                                    <div className="col-12 col-md-3">

                                        <Skeleton height={100} />

                                    </div>
                                    <div className="col-12 col-md-3">

                                        <Skeleton height={100} />

                                    </div>
                                    <div className="col-12 col-md-3">

                                        <Skeleton height={100} />

                                    </div>
                                    <div className="col-12 col-md-3">

                                        <Skeleton height={100} />

                                    </div>


                                </>

                                : <>
                                    {
                                        Categorys.map((el, i) => {
                                            return (
                                                <div className="col-md-3 mb-4 " key={i} data-aos="fade-up">
                                                    <Link className="category-item" to={"/category/" + el.id}>
                                                        <img className="img-fluid" src={el.image ? (url + "/uploads/logos/" + el.logo) : `${defaultImage}`} alt="" />
                                                        <strong className="category-item-title">{el.name}</strong>
                                                    </Link>
                                                </div>
                                            );
                                        })
                                    }
                                </>
                        }



                    </div>

                </div>

            </section>

            {/*Products SECTION*/}

            <section className="py-5">

                <div className="container-fluid px-5">
                    <header className="mb-5">
                        <p className="small text-muted small text-uppercase mb-1">Faites-vous plaisir avec les</p>
                        <h2 className="h5 text-uppercase ">Produits les plus tendance</h2>
                    </header>

                    <div className='row'>

                        {
                            Loading ?
                                <>
                                    <div className="col-12 col-md-3">

                                        <Skeleton height={140} />
                                        <Skeleton height={40} />

                                    </div>
                                    <div className="col-12 col-md-3">

                                        <Skeleton height={140} />
                                        <Skeleton height={40} />

                                    </div>
                                    <div className="col-12 col-md-3">

                                        <Skeleton height={140} />
                                        <Skeleton height={40} />

                                    </div>
                                    <div className="col-12 col-md-3">

                                        <Skeleton height={140} />
                                        <Skeleton height={40} />

                                    </div>

                                </>

                                : <>
                                    {
                                        Datas.map((el, i) => {
                                            return (
                                                <ProductItem data={el} index={i} key={i} />
                                            );
                                        })
                                    }
                                </>
                        }



                    </div>
                </div>
            </section>

            {/*Pub SECTION*/}
            <PubSpace />

            <Footer />
        </>
    );

};

export default Homepage;
