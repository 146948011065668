import React from 'react';
import {Link} from "react-router-dom";
import {url} from "../../constants/Constants";


const ProductItem = ({data, index}) => {

    return (
        <div className="col-12 col-md-3 mb-4 " key={index}
             data-aos="fade-up"
             data-aos-duration="4000"

             >
            <div className="product--cyan h-100">
                <div className='product_inner'>

                    <Link to={"/article/" + data.id}>
                        <img
                            src={url + "/uploads/products/" + data.cover}
                            width="300" alt="Marchandise"/>
                    </Link>

                </div>
                <div className="product_price   ">
                    <Link to={"/article/" + data.id} className="text-left">
                        <p className="mb-0">
                            {data.name}
                        </p>

                        <p className="text-lowercase des small text-muted">{data.product_reference}</p>

                        <p>$ {data.prix}</p>

                    </Link>
                </div>

            </div>
        </div>
    );

};

export default ProductItem;
