import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import defaultImage from "../../Assets/images/Cat-bb/bb-tiranta-paysage.jpg"
import Trianta from "../../constants/Api";
import Skeleton from "react-loading-skeleton";
import ProductItem from "../../Components/ProductItem";
import { useParams } from "react-router-dom";


const TrinataShopPage = () => {

    const { keyword } = useParams();
    const [Datas, setDatas] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [KeyWord, setKeyWord] = useState("");
    // const [Filter, setFilter] = useState("nom_crois");
    // const [DatasFilter, setDatasFilter] = useState([]);

    function Search(e) {

        e.preventDefault();
        window.location.replace("/recherche/" + KeyWord);
    }

    useEffect(() => {

        Trianta.Product.getbyKeyword(keyword).then(res => {
            if (res.data) {
                setDatas(res.data);
                setTimeout(() => {
                    setLoading(false)
                }, 1500)
            }
        }).catch();

        
    }, [keyword]);



    return (
        <>
            <Navbar />

            <section className="hero" id="categorie" style={{ backgroundImage: `url(${defaultImage})` }}>

                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <h1 className="text-white">
                                Recherche : {keyword}
                            </h1>
                            <h4 className="text-muted">Résultat de la recherche : {Datas ? Datas.length : ""}</h4>
                        </div>
                    </div>
                </div>

            </section>



            <section className="py-5">
                <div className="container-fluid">
                    <div className="row">



                        <div className="col-12 col-md-12">

                            <div className="row pt-4">

                                {
                                    Loading ?
                                        <>
                                            <div className="col-12 col-md-3">

                                                <Skeleton height={140} />
                                                <Skeleton height={40} />

                                            </div>
                                            <div className="col-12 col-md-3">

                                                <Skeleton height={140} />
                                                <Skeleton height={40} />

                                            </div>
                                            <div className="col-12 col-md-3">

                                                <Skeleton height={140} />
                                                <Skeleton height={40} />

                                            </div>
                                            <div className="col-12 col-md-3">

                                                <Skeleton height={140} />
                                                <Skeleton height={40} />

                                            </div>

                                        </>

                                        : <>
                                            {
                                                Datas && Datas.length !== 0 ?
                                                    Datas.map((el, i) => {
                                                        return (
                                                            <ProductItem data={el} index={i} key={i} />
                                                        );
                                                    })
                                                    : <>
                                                        <div className="col-12 col-md-12">
                                                            <p>Aucun produit ne correspond mot-clé que vous avez saisit.</p>

                                                            <div className="row ">

                                                                <div className="col-md-7 col-12">
                                                                    <form onSubmit={Search}>
                                                                        <div className="input-group-lg input-group mb-3 ">
                                                                            <input type="text" className="form-control input-search" name="keyword"
                                                                                onChange={(e) => setKeyWord(e.target.value)}
                                                                                placeholder="Trouver un produit" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                                                            <div className="input-group-append">
                                                                                <button className="btn btn-dark-search" type="submit" id="button-addon2">
                                                                                    <i className="fas fa-search" aria-hidden="true"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </>

                                            }
                                        </>
                                }



                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/*  Footer  */}
            <Footer />
        </>
    );
};

export default TrinataShopPage;