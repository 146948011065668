import React, {useEffect, useState} from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { url} from "../../constants/Constants";
import Trianta from "../../constants/Api";
import $ from "jquery";
import {Link} from "react-router-dom";
import {useAuth} from "../../Context";


const CheckoutPage = () => {


    let Total = 0;
    const [Loading, setLoading] = useState(false);
    const [Loading2, setLoading2] = useState(false);
    const [Error, setError] = useState(false);
    const [Error1, setError1] = useState(false);
    const {cart, userId_Bil, authTokens, updateCart} = useAuth();
    const [Cart, setCart] = useState(cart);
    const [cond, setCond] = useState(false);


    function handleBackClick() {
        // Scroll back to the title element...
        $(window).scrollTop(0);
        setCond(false);
    }

    function RemoveElement(i) {

        let pagnet = cart.filter(element => element.id_produit !== i);
        setCart(pagnet);
        sessionStorage.setItem("cart", JSON.stringify(pagnet));
        updateCart(pagnet);


    }

    const onLogin = (e) => {
        e.preventDefault();
        sessionStorage.setItem('redirect', window.location.pathname);
        $(".modal").modal("hide");
        window.location.replace('/login');
    };


    function SendCartWithoutLog(e) {

        e.preventDefault();
        setLoading(true);
        let formData = new FormData(e.target);
        formData.append("zip","7110501");
        formData.append("country","RD-Congo");
        // formData.append("country","RD-Congo");

        Trianta.Users.send_bil_adresse(formData).then(res => {

            if (res.data.result) {

                $("#modalDataUser").modal("hide");
                const Cart = JSON.parse(sessionStorage.getItem("cart"));
                let formData = new FormData();
                formData.append("bill_address", res.data.values);
                formData.append("commandes", JSON.stringify(Cart));

                Trianta.Commande.post(formData).then(com => {

                    if (com.data) {
                        setLoading(false);
                        sessionStorage.removeItem("cart");
                        $("#modalSuccess").modal("show");
                        setTimeout(() => {
                            $("#modalSuccess").modal("hide");
                        }, 1500);

                    }
                }).catch();
            }
            else {
                setLoading(false);
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 3525);
            }

        }).catch();

    }

    function SendCart() {

        if (authTokens) {

            setLoading2(true);

            const Cart = JSON.parse(sessionStorage.getItem("cart"));

            let formData = new FormData();
            formData.append("bill_address", userId_Bil);
            formData.append("user", authTokens);
            formData.append("commandes", JSON.stringify(Cart));


            if(userId_Bil){

                Trianta.Commande.post(formData).then(res => {

                    if (res.data.result) {
                        sessionStorage.removeItem("cart");
                        setLoading2(false);
                        $("#modalSuccess").modal("show");
                        setTimeout(() => {
                            $("#modalSuccess").modal("hide");
                            window.location.replace('/');
                        }, 1500);

                    }
                }).catch();
            }
            else {
                setLoading2(false);
                setError1(true);

            }

        }
        else {
            $("#modalDataUser").modal("show");
        }
    }

    useEffect(() => {

        setCart(JSON.stringify(sessionStorage.getItem("cart")));


    }, [cart]);

    useEffect(()=>{
        $(document).ready(function(){
            $(window).scrollTop(0);
        });
    },[]);

    return (
        <>
            <Navbar/>

            <div className="container ">
                <section className="py-5 bg-gray">
                    <div className="row px-4 px-lg-5 py-lg-4 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="h2 text-uppercase mb-0">Checkout</h1>
                        </div>
                        <div className="col-lg-6 text-lg-right">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-lg-end mb-0 px-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/"className="text-orange text-decoration-none">
                                            Accueil</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Panier</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </section>
            </div>

            <section className="py-5">

                <div className="container">
                    <h2 className="h5 text-uppercase mb-4">Détails du Panier</h2>

                    <div className="row">

                        <div className="col-12 col-md-12 px-0 px-md-4">

                            {
                                cond ?
                                    <div className="card w-100">
                                        <div className="card-body p-5">


                                            <h4 className="font-weight-bold mb-4">Livraison & Commande</h4>

                                            <ul className="list-unstyled">
                                                <li>
                                                    <span className="text-orange mr-2">-</span>
                                                    Pour toute Livraison à domicile, payement en espèces à la livraison.
                                                </li>
                                                <li>
                                                    <span className="text-orange mr-2">-</span>
                                                    Le frais de livraison , soit<span className="text-orange">3$</span>,
                                                    est payable pour moins de 20$ d’achat par facture et non par articles
                                                </li>

                                                <li> <span className="text-orange mr-2">-</span>Temps de livraison, <span className="text-orange">
                                            1 à 3 jours
                                        </span> (après confirmation )

                                                </li>

                                                <li> <span className="text-orange mr-2">-</span>
                                                    Commande 24H24 jours 7/7
                                                    Livraison 7 jour sur 7 de 8h-19h à l’heure voulue par le client.
                                                </li>

                                                <li>
                                                    <span className="text-orange mr-2">-</span>Si vous n’êtes pas satisfait de votre achat  , pour n’importe quelle raison , vous avez tout votre droit de nous retourner la marchandise en état livré mais à condition que çela soit le même jour de la réception du colis
                                                </li>
                                                <li>
                                                    <span className="text-orange mr-2">-</span>
                                                    En cas de refus d’article pour des raisons valables ( exemple articles qui ne pas en bon état) le frais de la livraison ne pas payant;
                                                </li>
                                                <li>
                                                    <span className="text-orange mr-2">-</span>
                                                    En cas de refus d’article pour des raisons personnelles , le frais de la livraison reste payant.
                                                </li>

                                            </ul>

                                            <div className="text-center text-md-right mt-5">

                                                <button onClick={()=>{handleBackClick()}} className="btn mr-0 mr-md-3 mt-3 mt-md-0 btn-checkout btn-lg btn-dark" >
                                                    <span>Retour au panier</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="card border-0 rounded-0 p-0 p-md-5 bg-gray" id="panier">

                                        <div className="card-body py-3 px-0 px-md-3">


                                            {

                                                Cart && Cart.length !== null
                                                    ? <>
                                                        <div className="table-responsive">
                                                            <table className="table table-borderless">
                                                                <thead>
                                                                <tr>

                                                                    <th scope="col">Image</th>
                                                                    <th scope="col">Nom </th>
                                                                    <th scope="col">Prix</th>
                                                                    <th scope="col">Quantité</th>
                                                                    <th scope="col" className="text-center">Actions</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>

                                                                {
                                                                    cart.length !== 0 ?
                                                                        cart.map((el, i) => {

                                                                            Total = (parseFloat(Total) + (parseFloat(el.prix) * parseFloat  (el.quantity)));

                                                                            return (
                                                                                <tr key={i}>

                                                                                    <td className="d-flex justify-content-center">
                                                                                        <div className="check-card-img">
                                                                                            <img src={url + "/uploads/products/" + el.image}
                                                                                                 className="img-fluid" alt=""/>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {el.name}
                                                                                    </td>
                                                                                    <td>
                                                                                        {el.prix}

                                                                                    </td>
                                                                                    <td>
                                                                                        {el.quantity}
                                                                                    </td>

                                                                                    <td className="text-center">

                                                                                        <button className="btn btn-sm btn-outline-dark"
                                                                                                title="Retirer du pagnet"
                                                                                                onClick={() => RemoveElement(el.id_produit)}>
                                                                                            <i className="fas fa-times"
                                                                                               aria-hidden="true"/>
                                                                                        </button>

                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }) : null
                                                                }

                                                    {/*            <tr className="list-unstyled pt-2 mb-0">*/}
                                                    {/*                <th scope="row">#</th>*/}
                                                    {/*                <td><strong className="text-uppercase font-weight-bold ">Total</strong>*/}
                                                    {/*                </td>*/}
                                                    {/*                <td></td>*/}
                                                    {/*                <td></td>*/}
                                                    {/*                <td></td>*/}
                                                    {/*                <td className="text-center">*/}
                                                    {/*<span className="font-weight-bold">*/}
                                                    {/*    USD {*/}
                                                    {/*    Total >= 20*/}
                                                    {/*        ? parseFloat(Total)*/}
                                                    {/*        : (Total !== 0 ? (parseFloat(Total) + parseFloat(3)) : 0)*/}
                                                    {/*}*/}
                                                    {/*</span>*/}
                                                    {/*                </td>*/}
                                                    {/*            </tr>*/}

                                                                {/*{*/}
                                                                {/*    Total !== 0 ?*/}
                                                                {/*        <tr className="bg-light pt-2 mb-0">*/}
                                                                {/*            <th scope="row">#</th>*/}
                                                                {/*            <td>*/}
                                                                {/*                <strong className="font-weight-bold ">*/}
                                                                {/*                    {*/}
                                                                {/*                        Total >= 20*/}
                                                                {/*                            ? <p>Livraison gratuite pour la ville de*/}
                                                                {/*                                Lubumbashi</p>*/}
                                                                {/*                            :*/}
                                                                {/*                            <p>Livraison à Lubumbashi : 3$</p>*/}
                                                                {/*                    }*/}
                                                                {/*                </strong>*/}
                                                                {/*            </td>*/}
                                                                {/*            <td></td>*/}
                                                                {/*            <td></td>*/}
                                                                {/*            <td></td>*/}
                                                                {/*            <td></td>*/}

                                                                {/*        </tr> : null*/}
                                                                {/*}*/}

                                                                </tbody>

                                                            </table>
                                                        </div>

                                                        <div className="col-12 col-md-12 mt-3 px-0 px-md-2 ">
                                                            <p className="pl-2 pl-md-1">Total :
                                                                <span className="font-weight-bold ml-1">
                                                                USD {
                                                                    Total >= 20
                                                                        ? parseFloat(Total)
                                                                        : (Total !== 0 ? (parseFloat(Total) + Number(3)) : 0)
                                                                }
                                                                    {
                                                                        Total >= 20
                                                                            ? <p>Livraison gratuite </p>
                                                                            :
                                                                            <p>(frais de livraison inclus : 3$) </p>
                                                                    }
                                                            </span>
                                                            </p>
                                                        </div>
                                                    </>
                                                    : <div className="col-md-12">
                                                        <p className="mb-0">Votre Panier est vide </p>
                                                    </div>}
                                        </div>
                                        <div className="">
                                            <div className="row">

                                                {
                                                    Error1 ?

                                                        <div className="col-md-12 px-5">
                                                            <div className="alert alert-danger" role="alert">
                                                                Veuillez fournir votre adresse de livraison sur votre page de profile
                                                            </div>
                                                        </div>

                                                        : null
                                                }

                                                <div className="col-12 col-md-12  px-5 pb-5 pb-md-2">
                                                    {cart && cart.length !== 0 &&
                                                    <div className="text-center text-md-right">

                                                        <button onClick={()=>{setCond(true); $(window).scrollTop(0);}} className="btn mr-0 mr-md-3 mb-3 mb-md-0 btn-checkout btn-lg btn-dark" >
                                                            <span>Lire les Conditions</span>
                                                        </button>

                                                        <button className="btn btn-checkout btn-lg btn-dark"
                                                                onClick={() => SendCart()}>

                                                            {
                                                                Loading2 ? <span className="spinner-border spinner-border-sm"
                                                                                 role="status"/> :
                                                                    <span>Confirmer mon panier</span>
                                                            }
                                                        </button>


                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }

                        </div>


                    </div>
                </div>
            </section>

            <div id="modalSuccess" className="modal fade" role="dialog">
                <div className="modal-dialog modal-dialog-centered">


                    <div className="modal-content">
                        <div className="page-body p-5">
                            <div className="head mb-4">
                                <h3 className="mt-2">Votre Panier a été envoyé avec succés</h3>

                            </div>

                            <h1 className="text-center">
                                <div className="checkmark-circle">
                                    <div className="background"></div>
                                    <div className="checkmark draw"></div>
                                </div>
                            </h1>

                        </div>
                    </div>
                </div>

            </div>



            <div id="modalDataUser" className="modal fade" role="dialog">

                <div className="modal-dialog modal-lg modal-dialog-centered">

                    <div className="modal-content">
                        <div className="modal-body p-5 ">
                            <div className="row">
                                <div className="col-12 col-md-12 ">
                                    <h4 className="font-weight-bold mb-4">
                                        Veuillez fournir les informations ci-dessous afin de passer votre commande
                                    </h4>
                                    <form onSubmit={SendCartWithoutLog}>

                                        <div className="row">
                                            <div className="col-lg-6 form-group">
                                                <label className="text-small" htmlFor="firstName">Nom <sup>*</sup></label>
                                                <input className="form-control form-control" required name="firstname"
                                                       type="text"
                                                       placeholder="Votre nom"/>
                                            </div>
                                            <div className="col-lg-6 form-group">
                                                <label className="text-small" htmlFor="lastName">Prénom <sup>*</sup></label>
                                                <input className="form-control form-control" required name="lastname" type="text"
                                                       placeholder="Votre prénom"/>
                                            </div>

                                            <div className="col-lg-6 form-group">
                                                <label className="text-small" htmlFor="phone">Numéro de
                                                    téléphone <sup>*</sup></label>
                                                <input className="form-control form-control" name="phone" required type="tel"
                                                       placeholder="Ex +243 999 999 999 999"/>
                                            </div>

                                            <div className="col-lg-6 form-group">
                                                <label className="text-small" htmlFor="city">Ville <sup>*</sup></label>
                                                <input className="form-control form-control" name="city" required  type="text"
                                                       placeholder="Ex. Lubumbashi"/>
                                            </div>

                                            <div className="col-lg-12 form-group">
                                                <label className="text-small" htmlFor="address">Adresse <sup>*</sup></label>
                                                <p className="small">
                                                    Ex : Numéro : 01 / Avenue : Kava-vubu / Quartier : Golf-Malela / Commune : Lubumbashi / Reference : Terminus Malela
                                                </p>
                                                <textarea className="form-control form-control" name="address"
                                                          rows={3}
                                                          required
                                                       placeholder="Lieu de livraison"/>
                                            </div>


                                            <div className="col-lg-12 form-group my-4">

                                                <button className="btn  btn-block btn-dark" type="submit">
                                                    {
                                                        Loading ? <span className="spinner-border spinner-border-sm"
                                                                        role="status"/> :
                                                            <span>Confirmer mon panier</span>
                                                    }
                                                </button>

                                            </div>

                                            {
                                                Error ?

                                                        <div className="col-md-12">
                                                            <div className="alert alert-danger" role="alert">
                                                                Veuillez remplir correctement les champs obligatoires
                                                            </div>
                                                        </div>

                                                    : null
                                            }


                                            <div className="col-lg-12 form-group text-center mb-0">
                                                <h6>
                                                    Vous avez déjà un compte ? <Link
                                                    onClick={onLogin} className="text-orange text-decoration-none"
                                                    to="/login">
                                                    Connectez-vous
                                                    </Link>
                                                </h6>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Footer/>
        </>
    );
};

export default CheckoutPage;
