import React from 'react';
import back from "../../Assets/images/market.jpg";
import {Link} from "react-router-dom"
const PubSpace = () => {

    return (

        <section style={{backgroundImage: `url(${back})`}}
                 className="bar no-mb color-white text-center bg-cover bg-center bg-fixed relative-positioned">
            <div className="dark-mask"></div>
            <div className="container">
                <div className="row ">

                    <div className="col-12 col-lg-4  col-md-4 mb-3 mb-lg-0">

                        <div className="media-body text-left ml-3">
                            <h6 className="text-uppercase font-weight-bold mb-1">Livraison & Service client </h6>
                            <p className="text-small mb-0 text-muted">Rapide et securisée, nous livrons à <b className="text-white">3$</b> pour la ville de Lubumbashi</p>
                            <p className="text-small mb-0 text-muted">Pour plus de 20$ d’achat,  livraison gratuite </p>
                        </div>

                    </div>

                    <div className="col-12 col-lg-4  col-md-4 mb-3 mb-lg-0">
                        <div className="media-body text-left ml-3">
                            <h6 className="text-uppercase font-weight-bold mb-1">Besoin d'aide ?</h6>
                            <p className="text-small mb-0 text-muted">
                                Contactez-nous au numero :  <b>+243 840 626 332</b><br/> Ou à l'adresse email : <b>info@triantashop.com</b>
                            </p>
                        </div>

                    </div>
                    <div className="col-12 col-lg-4  col-md-4 mb-3 mb-lg-0">
                        <div className="media-body text-left ml-3">

                            <h6 className="text-uppercase font-weight-bold mb-1">Conditions d'utilisation</h6>
                            <p className="text-small text-muted">
                                Pour tous les détails sur nos conditions d'utilisation, Cliquez ici :
                            </p>

                            <Link to="/reglements" className="btn btn-block btn-md btn-dark">
                                <span>Nos Conditions</span>
                            </Link>

                        </div>

                    </div>
                    {/*<div className="col-lg-4">*/}
                    {/*            <div className="media-body text-left ml-3">*/}
                    {/*                <h6 className="text-uppercase mb-1">Lorem ipsum dolor</h6>*/}
                    {/*                <p className="text-small mb-0 text-muted">Lorem ipsum dolor sit amet</p>*/}
                    {/*            </div>*/}
                    {/*</div>*/}

                </div>
            </div>
        </section>


    );

};

export default PubSpace;