import React from 'react'
import PropTypes from 'prop-types'

export default function Input({name, children, type = 'text', error, placeholder = null, className, ...props}) {
    return <>
        <input
            type={type}
            className={error ? className + " is-invalid" : className}
            name={name}
            placeholder={children ? children : placeholder}
            {...props}
            id={name}/>
        {error && <div className="invalid-feedback mt-2">{error}</div>}


    </>

};

Input.propTypes = {
    name: PropTypes.string,
    children: PropTypes.node,
    type: PropTypes.string,
    className: PropTypes.string
};
